export const PUB_ADS_ID = '43';
export const KADAM_ID = '33';
export const EXO_CLICK_ID = '8';
export const TRAFFIC_STARTS_ID  = '9';
export const OCTO_CLICK = '27';
export const HILLTOP = '19';
export const ALL = 'All';

export const ADVISERS_LIST = [
	{
		name: ALL,
		value: '',
	},
	{
		name: 'pubAds',
		value: PUB_ADS_ID,
	},
	{
		name: 'Kadam',
		value: KADAM_ID,
	},
	{
		name: 'ExoClick',
		value: EXO_CLICK_ID,
	},
	{
		name: 'TrafficStars',
		value: TRAFFIC_STARTS_ID,
	},
	{
		name: 'OctoClick',
		value: OCTO_CLICK,
	},
	{
		name: 'Hilltop Ads',
		value: HILLTOP
	}
]
